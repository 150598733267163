.g-header{
    display: flex;
    justify-content: center;
   
}

.g-parent{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.g-photo img{
    width: 320px;
    height: 350px;
    padding: 20px;

    

}

.g-button {

      display: flex;
      justify-content: center;
     
      
    
}


.g-photo :is(img) {
    object-fit: cover;
    transition: all 0.3s linear;
}

.g-photo:hover{
    transform: scale(1.1);
}

.g-button button{
    width:200px;
    height: 40px;
    border: 1px solid black;
    
    
    
}

.g-button button:hover{
 background-color: antiquewhite;



}




@media only screen and (max-width: 767px) {


.g-parent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

}