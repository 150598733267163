.points{
    display: flex;
    
    flex-direction: row;
    
}

.padd{
    padding-left: 30px;
}



.about{
   margin-top: 0px;
}

.about-img img{
 width: 600px;
 border-radius: 30px;
}