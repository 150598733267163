/* General Layout */
.f-all {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 20px;
    background-color: #1c1c1c;  /* Dark background for contrast */
    color: #ffffff;  /* White text for readability */
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
}

.f1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
    text-align: center;
}

.f1 h1 {
    color: #ffcc00;  /* Accent color for titles */
    margin-bottom: 10px;
    font-family: "Playfair Display", serif;
}

.f1 h5 {
    max-width: 250px;
    color: #ffffff;
    font-size: 15px;
    line-height: 1.5;
    margin: 5px 0;
}

/* Contact and Address Styling */
.f1 h5 a {
    color: #ffcc00;  /* Matching link color with title */
    text-decoration: none;
}

.f1 h5 a:hover {
    color: #ffffff;  /* Hover effect for link */
    text-decoration: underline;
}

/* Mobile Responsiveness */
@media only screen and (max-width: 767px) {
    .f-all {
        flex-direction: column; 
        align-items: center;
        padding: 20px 0;
    }

    .f1 {
        margin-bottom: 20px;
    }

    .f1 h5 {
        max-width: 200px;
        font-size: 14px;
    }
}
