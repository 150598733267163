.m-photos1{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.m-photos11 img{
  width: 300px;
  height: 350px;
  border-radius: 30px;
}


@media only screen and (max-width: 767px) {

.m-photos1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.m-photos11{
  padding-bottom: 20px;
}


}




