/* General Styling */
.events {
    background-color: #1a1a1a; /* Dark background */
    padding: 0px 0;
    color: #f1f1f1; /* Light text for readability */
}

.titulo-seccion {
    font-size: 2.5em;
    font-family: "Playfair Display", serif;
    text-align: center;
    color: #ffcc00; /* Accent color */
    margin-bottom: 10px;
}
.titulo-seccion h1{
    font-family: "Playfair Display", serif;
}

.subtitle {
    text-align: center;
    color: #e0e0e0;
    font-size: 1.2em;
    margin-bottom: 30px;
}

.text-content h4 {
    color: #ffcc00;
    font-size: 1.8em;
    font-family: "Playfair Display", serif;
    margin-bottom: 10px;
}

.text-content p {
    font-family: 'Roboto', sans-serif;
    font-size: 1.1em;
    line-height: 1.6;
    color: #cccccc;
}

/* Carousel Item Styling */
.carousel-custom .carousel-item {
    padding: 20px 0;
   
    border-radius: 10px;
}

.imgss {
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.imgss:hover {
    transform: scale(1.05); /* Subtle hover effect */
}

/* Responsive Design */
@media only screen and (max-width: 768px) {
    .titulo-seccion {
        font-size: 2em;
    }

    .text-content p {
        font-size: 1em;
    }

    .subtitle {
        font-size: 1em;
    }
}
