/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/

.about .content h3 {
    font-weight: 600;
    font-size: 26px;
}

.about .content ul {
    list-style: none;
    padding: 0;
}

.about .content ul li {
    padding-bottom: 10px;
}

.about .content ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #cda45e;
}

.about .content p:last-child {
    margin-bottom: 0;
}

@media (min-width: 1024px) {
    .about {
        background-attachment: fixed;
    }
}

.imgss {
    max-width: 100%;
    height: auto;
  }
  
  .carousel-inner {
    max-height: 45rem;
  }
  
  .pad{
    padding-left: 10%;
    padding-right: 10%;
}

/*--------------------------------------------------------------
# Events
--------------------------------------------------------------*/

.events {
    background: url('../../../Assets/g1.jpg') center center no-repeat;
    background-size: cover;
    position: relative;
}

.events::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.events .section-title h2 {
    color: #fff;
}

.events .container {
    position: relative;
}

@media (min-width: 1024px) {
    .events {
        background-attachment: fixed;
    }
}

.events .events-carousel {
    background: rgba(255, 255, 255, 0.08);
    padding: 30px;
}

.events .event-item {
    color: #fff;
}

.events .event-item h3 {
    font-weight: 600;
    font-size: 26px;
    color: #cda45e;
}

.events .event-item .price {
    font-size: 26px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    margin-bottom: 15px;
}

.events .event-item .price span {
    border-bottom: 2px solid #cda45e;
}

.events .event-item ul {
    list-style: none;
    padding: 0;
}

.events .event-item ul li {
    padding-bottom: 10px;
}

.events .event-item ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #cda45e;
}

.events .event-item p:last-child {
    margin-bottom: 0;
}

.events .swiper-pagination {
    margin-top: 30px;
    position: relative;
}

.events .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: rgba(255, 255, 255, 0.4);
    opacity: 1;
}

.events .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #cda45e;
}

.slider-eventos{
    margin-bottom: 5%;
}